<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="수급업체 순회점검 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="patrol.saiPatrolVendorId && !disabled && editable"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="patrol"
                  mappingType="PUT"
                  label="LBLCOMPLETE"
                  icon="check"
                  @beforeAction="completePlan"
                  @btnCallback="completeCallback" 
                />
                <c-btn v-if="patrol.saiPatrolVendorId && !disabled && editable" label="LBLREMOVE" icon="delete_forever" @btnClicked="remove" />
                <c-btn
                  v-show="!disabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="patrol"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :disabled="disabled"
                  :editable="editable && !patrol.saiPatrolVendorId" 
                  type="edit" 
                  name="plantCd" 
                  v-model="patrol.plantCd"
                  @datachange="datachange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="순회일"
                  type="date"
                  name="patrolDate"
                  v-model="patrol.patrolDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-vendor
                  required
                  :editable="editable"
                  :disabled="disabled"
                  :selfFlag="false"
                  label="협력업체"
                  name="vendorCd"
                  v-model="patrol.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable"
                  :data="patrol"
                  deptValue="partolDeptCd"
                  type="dept_user"
                  label="점검자/부서"
                  name="patrolUserId"
                  v-model="patrol.patrolUserId">
                </c-field>
                <!-- <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="순회장소"
                  name="sopMapId"
                  v-model="patrol.sopMapId"
                /> -->
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="순회명"
                  name="patrolName"
                  v-model="patrol.patrolName">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="grid"
            title="항목별 결과 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="patrol.patrolVendorResultModelList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="항목별 결과 추가하세요."
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="saiPatrolVendorResultId"
            selection="multiple"
          >
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol'">
                <component
                  :is="imprComponent"
                  :col="col"
                  :props="props"
                  :inputEditable="editable&&!disabled"
                  :isImmShow="false"
                  :vendorCd="patrol.vendorCd"
                  :requestContentsCols="requestContentsCols"
                  tableKey="saiPatrolVendorResultId"
                  ibmTaskTypeCd="ITT0000066"
                  ibmTaskUnderTypeCd="ITTU000115"
                  @imprChange="imprChange"
                />
              </template>
            </template>
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addResult"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    patrol.patrolVendorResultModelList.length > 0
                  "
                  :showLoading="false"
                  label="LBLEXCEPT"
                  icon="remove"
                  @btnClicked="removeResult"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="순회결과 정보" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  codeGroupCd="PATROL_VENDOR_RESULT_CD"
                  :editable="editable"
                  type="edit"
                  :disabled="disabled"
                  itemText="codeName"
                  itemValue="code"
                  name="patrolResultCd"
                  label="순회결과"
                  v-model="patrol.patrolResultCd">
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="4"
                  label="순회결과요약"
                  name="patrolResultSummary"
                  v-model="patrol.patrolResultSummary">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="관련파일" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable&&!disabled">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'patrol-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolVendorId: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      patrol: {
        saiPatrolVendorId: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        sopMapId: '',
        patrolTypeCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultCd: null,
        patrolResultSummary: '',
        patrolCompleteFlag: 'N',
        sopConstructionId: '',
        regUserId: '',
        chgUserId: '',
        patrolUserId: '',

        patrolVendorResultModelList: [],
        deletePatrolVendorResultModelList: [],
        patrolVendorImproveModelList: [],
        deletePatrolVendorImproveModelList: [],
      },
      grid: {
        columns: [
          
        ],
        height: "360px",
        data: [],
      },
      splitter: { data: 240 },
      tab: 'result',
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      saveCall: {
        saveCallData: '',
      },
      saveUrl: 'transactionConfig.sai.patrolvendor.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      isComplete: false,
      completeUrl: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PATROL_VENDOR_RESULT',
        taskKey: '',
      },
      height: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.patrol.patrolCompleteFlag === "Y";
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['patrolItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
    'patrol'() {
      this.$emit('transInfo', this.patrol);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.patrol.patrolDate = this.$comm.getToday();
      this.getUrl = selectConfig.sai.patrolvendor.get.url;
      this.insertUrl = transactionConfig.sai.patrolvendor.insert.url;
      this.updateUrl = transactionConfig.sai.patrolvendor.update.url;
      this.deleteUrl = transactionConfig.sai.patrolvendor.delete.url;
      this.completeUrl = transactionConfig.sai.patrolvendor.complete.url;
      // list setting
      this.setHeader();
      this.getDetail();
    },
    setHeader() {
      this.$comm.getComboItems('PATROL_VENDOR_RESULT_CHECK_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'patrolItemName',
            field: 'patrolItemName',
            label: '점검항목',
            align: 'left',
            type: 'textarea',
            style: 'width:30%',
            sortable: false,
          },
          {
            required: true,
            name: 'patrolResultCd',
            field: 'patrolResultCd',
            label: '점검결과',
            setHeader: true,
            align: 'center',
            style: 'width:10%',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            setHeader: true,
            name: 'patrolContents',
            field: 'patrolContents',
            label: 'LBLREMARK',
            align: 'left',
            type: 'textarea',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ]
      });
    },
    getDetail() {
      if (this.popupParam.saiPatrolVendorId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.saiPatrolVendorId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.patrol.patrolVendorResultModelList = [];
          this.patrol = _result.data;
          this.$set(this.attachInfo, 'taskKey', _result.data.saiPatrolVendorId)
        },);
      } else {
        this.patrol.patrolUserId = this.$store.getters.user.userId;
        this.patrol.patrolName = this.patrol.patrolDate + ' 순회점검'
        this.$http.url = selectConfig.sai.patrolvendor.resultItem.list.url;
        this.$http.type = 'GET';
        this.$http.param = {
          useFlag: 'Y',
          plantCd: this.patrol.plantCd
        };
        this.$http.request((_result) => {
          this.patrol.patrolVendorResultModelList = [];
          this.$_.forEach(_result.data, _item => {
            this.patrol.patrolVendorResultModelList.push({
              saiPatrolVendorId: this.patrol.saiPatrolVendorId,
              saiPatrolVendorResultId: uid(),
              patrolItemName: _item.patrolItemName,
              patrolResultCd: 'PVRCC00001',
              patrolContents: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            }) 
          })
        },);
      }
    },
    savePlan() {
      if (this.popupParam.saiPatrolVendorId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.patrol.regUserId = this.$store.getters.user.userId
              this.patrol.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      if (!this.popupParam.saiPatrolVendorId) {
        this.$emit('changeStatus')
        this.popupParam.saiPatrolVendorId = _result.data
        this.popupParam.vendorCd = this.patrol.vendorCd
      }
      this.getDetail();
    },
    completePlan() {
      this.saveUrl = this.completeUrl;
      this.saveType = 'PUT';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.patrol.regUserId = this.$store.getters.user.userId
              this.patrol.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.patrol.saiPatrolVendorId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange(data) {
      this.patrol.patrolVendorResultModelList = [];
      if(!data.value) {
        return;
      } else {
        this.$http.url = selectConfig.sai.patrolvendor.resultItem.list.url;
        this.$http.type = 'GET';
        this.$http.param = {
          useFlag: 'Y',
          plantCd: this.patrol.plantCd
        };
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.patrol.patrolVendorResultModelList.push({
              saiPatrolVendorId: this.patrol.saiPatrolVendorId,
              saiPatrolVendorResultId: uid(),
              patrolItemName: _item.patrolItemName,
              patrolResultCd: 'PVRCC00001',
              patrolContents: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            }) 
          })
        },);
      }
    },
    addResult() {
      this.patrol.patrolVendorResultModelList.splice(0, 0, {
        saiPatrolVendorId: this.patrol.saiPatrolVendorId,
        saiPatrolVendorResultId: uid(),
        patrolItemName: '',
        patrolResultCd: 'PVRCC00001',
        patrolContents: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeResult() {
      let selectData = this.$refs["grid"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deletePatrolVendorResultModelList) {
            this.patrol.deletePatrolVendorResultModelList = [];
          }
          if (
            this.$_.findIndex(this.patrol.deletePatrolVendorResultModelList, {
              saiPatrolVendorResultId: item.saiPatrolVendorResultId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.patrol.deletePatrolVendorResultModelList.push(item);
          }
          this.patrol.patrolVendorResultModelList = this.$_.reject(
            this.patrol.patrolVendorResultModelList,
            item
          );
        });
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
